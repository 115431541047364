// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-buy-confirmation-en-us-jsx": () => import("./../src/pages/buy/confirmation.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-confirmation-en-us-jsx" */),
  "component---src-pages-buy-delivery-en-us-jsx": () => import("./../src/pages/buy/delivery.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-delivery-en-us-jsx" */),
  "component---src-pages-buy-index-en-us-jsx": () => import("./../src/pages/buy/index.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-index-en-us-jsx" */),
  "component---src-pages-buy-payment-en-us-jsx": () => import("./../src/pages/buy/payment.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-payment-en-us-jsx" */),
  "component---src-pages-buy-review-en-us-jsx": () => import("./../src/pages/buy/review.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-review-en-us-jsx" */),
  "component---src-pages-buy-shipping-en-us-jsx": () => import("./../src/pages/buy/shipping.en-us.jsx" /* webpackChunkName: "component---src-pages-buy-shipping-en-us-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */)
}

