import { Helmet } from 'react-helmet';
import React from 'react';

import appleTouchIcon from '../images/favicons/apple-touch-icon.png';
import favicon32px from '../images/favicons/32px.png';
import favicon192px from '../images/favicons/192px.png';


// See this URL to get a feel for the 'Gatsby style' of adding metadata and anything else to the
// head tag: https://www.gatsbyjs.org/docs/add-page-metadata/
const Head = () => (
  <Helmet>
    <meta name="viewport" content="minimum-scale=1,maximum-scale=1, initial-scale=1, width=device-width" />
    <title>Well World</title>
    <link
      href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
      rel="stylesheet"
    />
    <link
      href="https://fonts.googleapis.com/css?family=Roboto%20Condensed:300,400,500,700&display=swap"
      rel="stylesheet"
    />
    <link
      href={favicon32px}
      rel="icon"
      sizes="32x32"
    />
    <link
      href={favicon192px}
      rel="icon"
      sizes="192x192"
    />
    <link
      href={appleTouchIcon}
      rel="apple-touch-icon"
    />
  </Helmet>
);

export default Head;
