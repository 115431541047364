import React from 'react';
import fetch from 'isomorphic-fetch';
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  ApolloProvider,
} from '@apollo/client';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: new HttpLink({
    uri: process.env.GATSBY_PROXY_URL || 'https://m2-proxy-stg.dfh.dev:3000/graphql'.trim(),
    useGETForQueries: true,
    fetch,
  }),
  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});


// eslint-disable-next-line import/prefer-default-export
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>{element}</ApolloProvider>
);
