import { createMuiTheme } from '@material-ui/core/styles';

import palette from './palette';
import typography, { fontFamilyRobotoCondensed } from './typography';


const customTheme = createMuiTheme({
  overrides: {
    MuiButton: {
      containedPrimary: {
        backgroundColor: palette.primary.main,
        color: palette.primary.light,
        borderRadius: 4,
      },
      root: {
        height: '50px',
        '&:hover': {
          backgroundColor: palette.background.default,
          textDecoration: 'underline',
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&$checked': {
          '&:hover': {
            backgroundColor: palette.background.default,
          },
        },
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.paddingLeftRight': {
          paddingLeft: '15px',
          paddingRight: '15px',
        },
        '.robotoCondensed': {
          fontFamily: fontFamilyRobotoCondensed,
          fontStretch: 'condensed',
        },
      },
    },
    MuiDialogContentText: {
      root: {
        // Prevent weird 'border' around the body of the Dialog when it is clicked:
        '&:focus': {
          outline: 'unset',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        backgroundColor: palette.primary.main,
        '& h2': {
          color: palette.primary.light,
        },
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: '10px',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#424242',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '0.875rem',
      },
      root: {
        borderRadius: 4,
      },
    },
    MuiInputAdornment: {
      root: {
        fontWeight: 'bold',
      },
    },
    MuiIconButton: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
      root: {
        color: palette.primary.main,
        '&:hover': {
          backgroundColor: palette.background.default,
        },
      },
    },
    MuiLink: {
      // Override any links that would normally only be underlined on hover
      // to always be underlined:
      underlineHover: {
        textDecoration: 'underline',
      },
    },
    MuiOutlinedInput: {
      // Empty objects for disabled and focused are required to allow MUI's theme-creation
      // code to handle the references to `&$disabled` and `&$focused` below.
      disabled: {},
      focused: {},
      notchedOutline: {
        borderColor: '#E5E5E5',
      },
      root: {
        borderWidth: '1px',
        '&:hover $notchedOutline': {
          borderColor: '#E5E5E5',
        },
        // Ensure the border matches the grayed-out text within the input:
        '&$disabled $notchedOutline': {
          borderColor: palette.action.disabled,
        },
        // Prevent the border from ever becoming thicker on hover or focus:
        '&$focused $notchedOutline': {
          borderColor: '#E5E5E5',
          borderWidth: '1px',
        },
        // Override the weird rules MUI puts in place for devices that don't have hover states:
        '@media(hover: none)': {
          '&:hover $notchedOutline': {
            borderColor: '#E5E5E5',
          },
          '&$disabled $notchedOutline': {
            borderColor: palette.action.disabled,
          },
        },
      },
    },
    MuiToolbar: {
      root: {
        height: '100%',
      },
    },
    MuiTooltip: {
      tooltip: {
        // Override the `fade` applied to the `backgroundColor` in the `Tooltip`
        // component. More info in the source code here:
        //  https://github.com/mui-org/material-ui/blob/v4.9.14/packages/material-ui/src/Tooltip/Tooltip.js#L91
        backgroundColor: '#616161',
        fontSize: '0.75rem',
      },
    },
    MuiListItem: {
      root: {
        paddingBottom: '0',
        paddingTop: '0',
      },
    },
  },
  palette,
  props: {
    MuiButton: {
      disableElevation: true, // Remove all boxShadows from every button.
      disableFocusRipple: true,
      disableRipple: true,
    },
    MuiButtonBase: {
      disableRipple: true,
      disableTouchRipple: true,
    },
  },
  spacing: 2,
  typography,
});

export default customTheme;
