/* eslint-disable react/destructuring-assignment */
import { AppBar, Grid, Toolbar } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, makeStyles } from '@material-ui/core/styles';

import Head from './Head';

import customTheme from './custom_theme';
import logo from '../images/logo.svg';


const useStyles = makeStyles({
  appBar: {
    height: '65px',
    position: 'relative',
  },
  logo: {
    margin: '0 auto',
    width: '193px',
  },
});


const SharedPageLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <>
      <Head />
      <ThemeProvider theme={customTheme}>
        <CssBaseline />
        <AppBar position="absolute" className={classes.appBar} elevation={0}>
          <Toolbar>
            <img alt="Designs for Health" className={classes.logo} src={logo} />
          </Toolbar>
        </AppBar>
        <Grid
          alignItems="flex-start"
          component="main"
          container
          justify="center"
        >
          { children }
        </Grid>
      </ThemeProvider>
    </>
  );
};

SharedPageLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SharedPageLayout;
