const palette = {
  action: {
    // Value here matches the default value in the MUI palette. Needed here
    // so it can be referenced in the theme customization below.
    disabled: 'rgba(0, 0, 0, 0.26)',
  },
  background: {
    default: 'white',
  },
  error: {
    main: '#de4f56',
  },
  primary: {
    light: 'white',
    main: '#08203c',
  },
  secondary: {
    light: 'white',
    main: '#08203c',
  },
  text: {
    primary: '#000000',
  },
};

export default palette;
