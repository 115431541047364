// Default font fallback structure that can be destructured used to allow
// for other primary families without losing the fallbacks:
export const fontFamily = [
  'Roboto',
  'Helvetica',
  'Arial',
  'sans-serif',
];

export const fontFamilyRobotoCondensed = [
  'Roboto Condensed',
  ...fontFamily,
];

// To match Material UI's theming all fontSize values should use rem units.
// Conversion table available here assuming root fontSize is 16px:
//   http://www.standardista.com/px-to-rem-conversion-if-root-font-size-is-16px/
const typography = {
  button: {
    fontFamily: fontFamilyRobotoCondensed,
    fontSize: '0.9375rem',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontWeight: 'normal',
    height: '33px',
    letterSpacing: '0.9px',
    lineHeight: 'normal',
  },
  fontFamily,
  h4: {
    color: '#000000',
    fontSize: '0.9375rem',
    fontStretch: 'normal',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: 'normal',
    lineHeight: 'normal',
  },
  h5: {
    color: '#000000',
    fontFamily: fontFamilyRobotoCondensed,
    fontSize: '0.875rem',
    fontStretch: 'condensed',
    fontStyle: 'normal',
    fontWeight: 'bold',
    letterSpacing: '0.84px',
    lineHeight: 'normal',
    marginBottom: '20px',
  },
};

export default typography;
