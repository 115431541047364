/* eslint-disable react/destructuring-assignment */

import React from "react";
import PropTypes from "prop-types";

import SharedPageLayout from "./SharedPageLayout";

const TopLayout = ({ children }) => {
  return <SharedPageLayout>{children}</SharedPageLayout>;
};

TopLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TopLayout;
